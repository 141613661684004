import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { LoanModifier } from 'components/CarLoanCalculator/LoanModifier';
import { LoanType } from 'enums/LoanType';
import {
  calculateDownpaymentFromPercent,
  calculateMonthlyCost,
} from 'components/CarLoanCalculator/utils/calculateMonthlyCost';
import { BLIPP_FEE_PERCENT } from 'content/globalConstants';
import {
  getIdFromYears,
  getMonths,
  getYears,
} from 'components/CarLoanCalculator/utils/getResidualPercent';
import { VehicleType } from 'enums/VehicleType';
import { numberToFormatedString } from 'utils/numberToFormatedString';
import { Button, ButtonSize, ButtonType, ButtonVariant } from 'components/Button';
import { LoanSummary } from 'components/CarLoanCalculator/LoanSummary';
import { ExtraControls } from 'components/CarLoanCalculator/ExtraControls';
import { InputText } from 'components/InputText';

import RangePicker from './RangePicker/RangePicker';
import styles from './CarLoanCalculator.module.scss';
import InputSlider from './InputSliderNew/InputSlider';
import { DownpaymentDefaults } from './LoanModifier/LoanModifier';

const TOTAL_COST_DEFAULT = 120000;
const MIN_TOTAL_COST = 50000;
const MAX_TOTAL_COST = 1000000;

const MIN_PERIOD = 2;
const MAX_PERIOD = 8;

const MIN_RESIDUAL_VALUE = 0;

const residualPeriods = {
  24: 65,
  36: 55,
  48: 40,
  60: 30,
};

interface IProps {
  totalCost?: number;
  isInverted?: boolean;
  loanType: LoanType;
  isCouponValid?: boolean;
  hideExtraControls?: boolean;
}

export const CarLoanCalculator: React.FC<IProps> = ({
  totalCost,
  isInverted = false,
  loanType = LoanType.NordeaLoan,
  isCouponValid,
  hideExtraControls = true,
}) => {
  const { watch, setValue } = useFormContext();
  const [maxResidualValue, setMaxResidualValue] = useState(55);
  const [isResidualDisabled, setIsResidualDisabled] = useState(false);
  const [isLoanSummaryVisible, setIsLoanSummaryVisibile] = useState(false);
  const [showExtraControls, setShowExtraControls] = useState(false);

  const isTotalCostFixed = !!totalCost;
  const duration: string = watch('duration');
  const durationYears = getYears(duration);
  const total = watch('total-cost') || TOTAL_COST_DEFAULT;
  const residual = watch('residual_percent');
  const warrantyIsChecked = watch('blippinsurance');
  const investmentPercent = watch('investment_percent');
  const campaignCodeData = watch('campaign-code-data');
  const totalMonthCost = watch('total-month-cost');

  const totalWithFees =
    (totalCost || total) * (1 + BLIPP_FEE_PERCENT / 100) + (warrantyIsChecked ? 3995 : 0);

  const handlePriceChange = (value: number) => {
    setValue('total-cost', value);
  };

  const handleResidualChange = (value: number) => {
    setValue('residual_percent', value);
  };

  const handleToggleLoanSummaryClick = () => {
    setIsLoanSummaryVisibile(!isLoanSummaryVisible);
  };
  const handleToggleExtraControlsClick = () => {
    setShowExtraControls(!showExtraControls);
  };

  const handleDurationChange = (value: number) => {
    const durationId = getIdFromYears(value);
    setValue('duration', durationId);
  };

  useEffect(() => {
    if (!duration || !total) return;

    const months = getMonths(duration);

    setValue(
      'total-month-cost',
      calculateMonthlyCost({
        amount: total * (1 + BLIPP_FEE_PERCENT / 100),
        months,
        downpayment: calculateDownpaymentFromPercent(totalWithFees, investmentPercent),
        warrantyIsChecked,
        coupon: campaignCodeData,
        loanType,
        vehicleType: VehicleType.Car,
        residual,
      })
    );
  }, [
    setValue,
    duration,
    total,
    investmentPercent,
    warrantyIsChecked,
    campaignCodeData,
    loanType,
    residual,
    totalWithFees,
  ]);

  useEffect(() => {
    const months = getMonths(duration);
    if (months > 60) {
      setIsResidualDisabled(true);
      setMaxResidualValue(0);
      return;
    }
    setIsResidualDisabled(false);
    if (months <= 24) {
      setMaxResidualValue(residualPeriods[24]);
    } else if (months <= 36) {
      setMaxResidualValue(residualPeriods[36]);
    } else if (months <= 48) {
      setMaxResidualValue(residualPeriods[48]);
    } else if (months <= 60) {
      setMaxResidualValue(residualPeriods[60]);
    }
  }, [duration]);

  useEffect(() => {
    if (residual > maxResidualValue) {
      setValue('residual_percent', maxResidualValue);
    }
  }, [maxResidualValue, residual, setValue]);

  useEffect(() => {
    const maxDownpaymentRatio = Math.min(
      100 - residual,
      DownpaymentDefaults.InvestmentRatioMax * 100
    );

    if (investmentPercent > maxDownpaymentRatio) {
      setValue('investment_percent', maxDownpaymentRatio);
    }
  }, [residual, setValue, investmentPercent, total]);

  return (
    <div className={styles.calculator}>
      {!isTotalCostFixed && (
        <InputSlider
          className="mb-2"
          size="large"
          min={MIN_TOTAL_COST}
          max={MAX_TOTAL_COST}
          value={total}
          step={5000}
          onChange={handlePriceChange}
          suffix=" kr"
          label="Bilens pris"
          isInverted={isInverted}
        />
      )}
      <RangePicker
        className="mb-2"
        size="small"
        min={MIN_PERIOD}
        max={MAX_PERIOD}
        value={durationYears}
        step={1}
        onChange={handleDurationChange}
        suffix="år"
        label="Välj lånetid"
        isInverted={isInverted}
      />
      <ExtraControls isInverted={isInverted} hasHomeDeliveryOption={false} />
      <Button
        type={ButtonType.Button}
        size={ButtonSize.Small}
        variant={isInverted ? ButtonVariant.Primary : ButtonVariant.Tertiary}
        onClick={handleToggleExtraControlsClick}
      >
        {showExtraControls ? 'Visa mindre' : 'Ändra kontantinsats eller restvärde'}
      </Button>
      {hideExtraControls && showExtraControls ? (
        <>
          <LoanModifier isInverted={isInverted} totalCost={totalWithFees} />
          <InputSlider
            className="mb-2"
            size="small"
            min={MIN_RESIDUAL_VALUE}
            max={maxResidualValue}
            value={residual}
            step={5}
            onChange={handleResidualChange}
            suffix=" %"
            inputProps={{ disabled: isResidualDisabled }}
            isInverted={isInverted}
            label="Restvärde"
          />

          <div>
            <InputText
              label="Kampanjkod"
              name="campaign-code"
              size="medium"
              isCouponValid={isCouponValid}
            />
            {isCouponValid && <span className={styles.validCouponText}>Giltig kampanjkod</span>}
          </div>
        </>
      ) : null}

      <div className={styles.monthlyCost}>
        <span>Månadskostnad</span>
        <h2>{numberToFormatedString(Math.round(totalMonthCost))} kr/mån</h2>
        <small>Att använda Blipp kostar {BLIPP_FEE_PERCENT}% av bilens pris</small>
      </div>
      <Button
        type={ButtonType.Button}
        size={ButtonSize.Small}
        variant={isInverted ? ButtonVariant.LinkText : ButtonVariant.Tertiary}
        onClick={handleToggleLoanSummaryClick}
      >
        {isLoanSummaryVisible ? 'Visa mindre' : 'Så har vi räknat'}
      </Button>
      {isLoanSummaryVisible ? (
        <LoanSummary
          isInverted={isInverted}
          loanType={loanType}
          totalWithFees={totalWithFees}
          total={isTotalCostFixed ? totalCost : total}
        />
      ) : null}
    </div>
  );
};
