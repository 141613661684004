import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Link from 'next/link';
import Image from 'next/image';
import { ErrorMessage } from '@hookform/error-message';
import Smartlook from 'smartlook-client';

import { CarLoanCalculator } from 'components/CarLoanCalculator';
import { Button, ButtonSize, ButtonType, ButtonVariant } from 'components/Button';
import { arrowRight } from 'icons/default';
import { ICouponData } from 'types/CouponCode';
import { InputText } from 'components/InputText';
import { InputSelect } from 'components/InputSelect';
import { InputCheckbox } from 'components/InputCheckbox';
import { ClientApiService } from 'services/ClientApiService';
import { InputCheckboxSize } from 'components/InputCheckbox/InputCheckbox';
import useUser from 'lib/auth/useUser';
import { isLoggedIn } from 'lib/auth/AuthContext';
import { Gutter } from 'components/Gutter';
import { LoanType } from 'enums/LoanType';
import { inputBorderStyling } from 'components/sections/ControlHero/inputBorderStyling';

import { licensePlateRegex, phoneRegex, ssnRegex } from '../actions/validation';

import styles from './CalculateWithActions.module.scss';

// const ECSTER_TERMS_URL =
// 'https://www.ecster.se/globalassets/content/shared/documents/terms/villkor-lanelofte.pdf';

const NORDEA_TERMS_URL = 'https://www.nordea.com/sv/dataskyddspolicy';

enum Steps {
  Initial = 'initial',
  Contact = 'contact',
  Ssn = 'ssn',
  Kyc = 'kyc',
}

interface IProps {
  isInverted?: boolean;
  couponData?: ICouponData;
  loanType: LoanType;
  hideExtraControls?: boolean;
}

export const CalculateWithActions: React.FC<IProps> = ({
  isInverted = true,
  couponData,
  loanType,
  hideExtraControls = false,
}) => {
  const [step, setStep] = useState(Steps.Initial);
  const {
    setError,
    trigger,
    watch,
    register,
    clearErrors,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { user } = useUser();
  const email = watch('email');
  const phone = watch('phone');
  const licenseValue = watch('licensePlate');
  const [isCouponValid, setIsCouponValid] = useState<any | null>(null);

  useEffect(() => {
    if (couponData) {
      clearErrors('campaign-code');
      setValue('campaign-code-data', couponData.coupon);
    }
    if (couponData && couponData.status.errors.length === 0) {
      setIsCouponValid(true);
    } else {
      setIsCouponValid(false);
    }
  }, [couponData, setError, setValue, clearErrors]);

  const validateContact = async () => {
    const isValidEmail = await trigger('email');
    const isValidPhone = await trigger('phone');

    return isValidEmail && isValidPhone;
  };

  const goToLicensePlateStep = () => {
    if (isLoggedIn(user) && licenseValue && licenseValue.match(licensePlateRegex)) {
      // Skip to kyc if there is a license plate and the user is logged in
      setStep(Steps.Kyc);
    } else {
      setStep(Steps.Ssn);
    }
  };

  const handleClickContactButton = async () => {
    Smartlook.track('loan-application', {
      step: 'contact-information',
    });
    const isContactValid = await validateContact();

    if (!isContactValid) return;

    // request
    const response = await ClientApiService.proxyRequest({
      method: 'postContact',
      payload: { email, phone, source: 'nordealoan' },
    });
    const responseJson = await response.json();

    // handle error
    if (response.status !== 200) {
      responseJson.body.errors.forEach(({ field, message }: { field: string; message: string }) => {
        if (field === 'email') {
          setError('email', {
            type: 'custom',
            message,
          });
        }
      });

      return;
    }

    goToLicensePlateStep();
  };

  const handleClickInitial = () => {
    Smartlook.track('loan-application', {
      step: 'first-step',
    });
    if (isLoggedIn(user)) {
      goToLicensePlateStep();
      return;
    }

    setStep(Steps.Contact);
  };

  const handleClickSsn = async () => {
    Smartlook.track('loan-application', {
      step: 'ssn-licenseplate',
    });
    const isSsnValid = await trigger('ssn');
    const isLicensePlateValid = await trigger('licensePlate');

    if (!isSsnValid || !isLicensePlateValid) {
      return;
    }

    setStep(Steps.Kyc);
  };

  const existingAccountError =
    errors['general-error'] &&
    (errors['general-error'].message as any).includes('Kontot finns redan') ? (
      <Gutter size="tiny">
        <Button
          type={ButtonType.Button}
          variant={ButtonVariant.PrimaryYellowish}
          // Query strings does not handle `#` so it's replaced by `.`
          href="/app/login?returnTo=lanelofte-billan.loan"
        >
          Logga in för att gå vidare
        </Button>
      </Gutter>
    ) : (
      ''
    );

  return (
    <div
      className={`${styles['calculate-with-actions']}
      ${isInverted ? styles.inverted : ''}`}
    >
      <div id="loan" className={styles['car-loan-calculator-container']}>
        <CarLoanCalculator
          isInverted={isInverted}
          loanType={loanType}
          isCouponValid={isCouponValid}
          hideExtraControls={hideExtraControls}
        />
      </div>

      {step === Steps.Initial && (
        <Button
          type={ButtonType.Button}
          variant={ButtonVariant.PrimaryMintSweet}
          trailingIcon={arrowRight}
          onClick={handleClickInitial}
          size={ButtonSize.Large}
        >
          Påbörja ansökan
        </Button>
      )}

      {step === Steps.Contact && (
        <div className={styles['step-two']}>
          <div className={styles.distance} />

          <InputText
            registerOptions={{
              required: 'Du måste ange en giltig e-postadress',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Du måste ange en giltig e-postadress',
              },
            }}
            size="medium"
            label="E-postadress"
            name="email"
            type="text"
          />
          <InputText
            registerOptions={{
              required: 'Du måste ange ett giltigt telefonnummer',
              pattern: {
                value: phoneRegex,
                message: 'Du måste ange ett giltigt telefonnummer',
              },
            }}
            size="medium"
            label="Telefonnummer"
            name="phone"
            type="tel"
          />
          <Button
            onClick={() => handleClickContactButton()}
            type={ButtonType.Button}
            variant={ButtonVariant.PrimaryMintSweet}
            trailingIcon={arrowRight}
            size={ButtonSize.Large}
          >
            Fortsätt
          </Button>
        </div>
      )}

      {step === Steps.Ssn && (
        <div className={styles['step-three']}>
          <ErrorMessage
            name="general-error"
            errors={errors}
            render={({ message }) => <span className={styles.errorMessage}>{message}</span>}
          />

          {existingAccountError}

          <div className={styles.distance} />

          {!isLoggedIn(user) && (
            <InputText
              registerOptions={{
                required: true,
                validate: {
                  isValidSSN: (value: string) => {
                    if (!value) {
                      return 'Du måste ange ett giltigt personnummer';
                    }

                    if (value.length !== 12) {
                      return 'Personnummer måste vara 12 tecken långt';
                    }

                    if (!ssnRegex.test(value)) {
                      return 'Du måste ange ett giltigt personnummer';
                    }

                    return true; // Validering passerad
                  },
                },
              }}
              size="medium"
              type="number"
              label="ÅÅÅÅMMDDNNNN"
              name="ssn"
            />
          )}
          {loanType === LoanType.NordeaLoan && (
            <>
              <label className={styles['number-plate-for-text']} htmlFor="licensePlate">
                Bilens registreringsnummer
              </label>
              <div className={styles['number-plate']}>
                <Image
                  src="/assets/images/number-plate.svg"
                  alt="Number plate"
                  width={30}
                  height={60}
                />
                <input
                  {...register('licensePlate', {
                    required: 'Du behöver ange registreringsnumret på bilen du vill köpa',
                    pattern: {
                      value: licensePlateRegex,
                      message: 'Du måste ange ett giltigt registreringsnummer',
                    },
                    minLength: {
                      value: 6,
                      message: 'Ett registreringsnummer måste vara 6 tecken långt',
                    },
                  })}
                  id="licensePlate"
                  type="text"
                  style={inputBorderStyling[licenseValue?.length ?? 0]}
                  maxLength={6}
                  autoComplete="off"
                  required
                />
              </div>
              <ErrorMessage
                name="licensePlate"
                errors={errors}
                render={({ message }) => <span className={styles.errorMessage}>{message}</span>}
              />

              <div className={styles.distance} />
            </>
          )}

          <Button
            type={ButtonType.Button}
            variant={ButtonVariant.PrimaryMintSweet}
            trailingIcon={arrowRight}
            size={ButtonSize.Large}
            onClick={handleClickSsn}
          >
            Fortsätt
          </Button>
        </div>
      )}

      {step === Steps.Kyc && (
        <div className={styles['step-four']}>
          <div className={styles.distance} />

          <div className={styles.inputWrapper}>
            <InputText
              registerOptions={{
                required: 'Ange månadsinkomst (före skatt)',
              }}
              size="medium"
              label="Månadsinkomst före skatt"
              name="income"
              type="text"
            />
          </div>

          <InputSelect
            name="employment"
            options={['Anställd', 'Egen företagare', 'Pensionär', 'Studerande', 'Arbetslös/annat']}
            size="medium"
            defaultText="Sysselsättning"
            registerOptions={{
              required: 'Ange sysselsättning',
            }}
          />

          <InputSelect
            name="living"
            options={['Hyresrätt', 'Villa eller radhus', 'Bostadsrätt', 'Annat boende']}
            size="medium"
            defaultText="Boendeform"
            registerOptions={{
              required: 'Ange boendeform',
            }}
          />

          <div className={styles.inputWrapper}>
            <InputText
              registerOptions={{
                required: 'Ange antal vuxna i hushållet',
              }}
              size="medium"
              label="Antal vuxna i hushållet"
              name="number_of_adults"
              type="text"
            />
          </div>

          <div className={styles.inputWrapper}>
            <InputText
              registerOptions={{
                required: 'Ange antal barn i hushållet',
              }}
              size="medium"
              label="Antal barn i hushållet"
              name="number_of_children"
              type="text"
            />
          </div>

          <div className={styles['terms-container']}>
            <InputCheckbox
              registerOptions={{
                required: 'Du måste godkänna villkoren för att skicka in ansökan',
              }}
              size={InputCheckboxSize.Small}
              label={
                <>
                  Jag försäkrar att lämnade uppgifter är korrekta, att jag godkänner Blipps{' '}
                  <Link href="/anvandarvillkor">
                    <a>användarvillkor</a>
                  </Link>{' '}
                  och{' '}
                  <Link href="/integritetspolicy">
                    <a>integritetspolicy</a>
                  </Link>
                  , och att jag har tagit del av&nbsp;
                  <Link href={NORDEA_TERMS_URL} passHref>
                    <a className={styles.link} target="_blank">
                      Nordeas dataskyddspolicy
                    </a>
                  </Link>{' '}
                  Samt Nordeas{' '}
                  <Link href="/assets/download/allmanna-villkor-2023-06-15.pdf">
                    <a>allmänna villkor</a>
                  </Link>
                </>
              }
              name="terms"
            />
          </div>
          <div className={styles.distance} />
          <Button
            type={ButtonType.Submit}
            variant={ButtonVariant.PrimaryMintSweet}
            trailingIcon={arrowRight}
            size={ButtonSize.Large}
          >
            Slutför
          </Button>
          <div className={styles.distance} />
          <ErrorMessage
            name="general-error"
            errors={errors}
            render={({ message }) => <span className={styles.errorMessage}>{message}</span>}
          />

          {existingAccountError}
        </div>
      )}
    </div>
  );
};
