import React from 'react';
import Image from 'next/image';

import { LoanType } from 'enums/LoanType';
import { Modal } from 'components/Modal';
import { arrowRight, questionCircle } from 'icons/default';
import { Icon } from 'components/tokens/Icon';
import { Colors } from 'enums/Colors';
import { Button, ButtonSize, ButtonType, ButtonVariant } from 'components/Button';

import { Calculate } from '../Calculate';

import styles from './StartHero.module.scss';

export const StartHero: React.FC = () => (
  <div className={styles.wrapper}>
    <div className={`${styles.content} `}>
      <div>
        <h1>
          Det smarta sättet <br />
          att <span>köpa bil privat</span>
        </h1>
        <p>
          Med blipp:pay är det enklare än någonsin att köpa en bil privat. Låg ränta med ränteavdrag
          och experthjälp hela vägen. Slipp krånglet och få en trygg affär.
        </p>
        <ul>
          <li>Låg ränta för alla: 6,49 %</li>
          <li className={styles.modal_item}>
            Behåll ränteavdraget på 30 %{' '}
            <Modal ButtonContent={<Icon icon={questionCircle} size={20} color={Colors.White} />}>
              <h2>Ränteavdraget försvinner – vad betyder det för dig?</h2>
              <p>
                Ränteavdraget på 30 % är på väg att försvinna helt, vilket innebär att du inte
                längre kan dra av räntekostnader på dina privatlån.
              </p>
              <p>
                Eftersom privatlån ofta har högre räntor än lån med säkerhet blir det en större
                påverkan på din ekonomi.
              </p>
              <p>
                <strong>Blipps billån</strong> är ett lån med säkerhet, vilket innebär att du
                fortfarande kan dra av upp till 30 % på räntan även när ränteavdraget försvinner.
                Det gör att du kan fortsätta få en lägre ränta och en mer kostnadseffektiv lösning
                när du köper bil.
              </p>
            </Modal>
          </li>
          <li>Trygg affär: Vi hanterar lån, ägarbyte och försäkring</li>
        </ul>
        <div className={styles.howItWorksButton}>
          <Button
            type={ButtonType.Button}
            trailingIcon={arrowRight}
            size={ButtonSize.Large}
            variant={ButtonVariant.Tertiary}
            href="/kopa-bil-med-blipp"
            stretch
          >
            Så fungerar blipp:pay
          </Button>
        </div>
      </div>

      <div className={styles.trustpilot}>
        <span>Utmärkt</span>
        <Image
          width={120}
          height={24} // Lägg till höjd för optimal responsivitet
          src="/assets/icons/logo/Trustpilot_rating.svg"
          alt="Trustpilot betyg"
        />
        <a href="https://se.trustpilot.com/review/blipp.se" target="_blank" rel="noreferrer">
          <Image
            width={100}
            height={24} // Lägg till höjd för optimal responsivitet
            src="/assets/icons/logo/Trustpilot_brandmark.svg"
            alt="Trustpilot logotyp"
          />
        </a>
      </div>
    </div>
    <div className={`${styles.flex_item}`}>
      <Calculate
        hideExtraControls
        isInverted={false}
        hideHeadline
        hideImage
        loanType={LoanType.NordeaLoan}
      />
    </div>
  </div>
);
