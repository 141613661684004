import React from 'react';
import type { GetServerSideProps, NextPage } from 'next';
import Head from 'next/head';

import { Start } from 'components/views/Start';
import { faq } from 'content/faq';
import { StrapiService } from 'lib/strapi/StrapiService';
import { MagazineArticleCard } from 'components/sections/Magazine';
import { withSessionSsr } from 'lib/auth';

interface Props {
  articles: MagazineArticleCard[];
}

const Home: NextPage<Props> = ({ articles }) => (
  <>
    <Head>
      <title>Köp eller sälj bil med experthjälp - Blipp</title>
      <meta property="og:title" content="Köp eller sälj bil med experthjälp - Blipp" />
      <meta property="og:url" content="https://blipp.se/" />
      <meta
        property="og:description"
        content="Spara pengar när du ska köpa eller sälja begagnade bilar. Ansök om billån hos oss och köp din nya bil tryggt och enkelt!"
      />
      <meta
        name="description"
        content="Spara pengar när du ska köpa eller sälja begagnade bilar. Ansök om billån hos oss och köp din nya bil tryggt och enkelt!"
      />
    </Head>

    <Start articles={articles} faq={faq} />
  </>
);

export const getServerSideProps: GetServerSideProps = withSessionSsr(async () => {
  const articles = await StrapiService.getLatestMagazineArticles(3);

  return {
    props: {
      articles,
    },
  };
});

export default Home;
